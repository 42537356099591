<template>
  <div>
    <clinicList
      viewRoute="DoctorClinicDetail"
      :canViewDetail="true"
      :canViewPairDevice="false"
      :canPairDevice="false"
      :canUnassociateClinic="false"
    />
  </div>
</template>

<script>
import clinicList from "../../components/clinics/clinicList.vue";

export default {
  components: {
    clinicList
  },
  data: () => ({})
};
</script>